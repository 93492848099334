.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h4,
.home-page-wrapper > h4 {
  font-size: 52px;
  color: #fff;
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header0 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header0 .home-page {
  padding: 0 24px;
}
.header0-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header0-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header0-logo a {
  display: block;
}
.header0-menu {
  float: right;
}
.header0-menu .ant-menu {
  line-height: 62px;
  height: 64px;
}
.header0-menu .ant-menu a {
  display: block;
}
.header0-item-block {
  padding: 0 8px;
}
.header0-item-block > * {
  display: inline-block;
}
.header0-item .ant-menu-sub .ant-menu-item,
.header0-item-child .ant-menu-sub .ant-menu-item,
.header0-menu .ant-menu-sub .ant-menu-item,
.header0-item .ant-menu-inline .ant-menu-item,
.header0-item-child .ant-menu-inline .ant-menu-item,
.header0-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header0-item .item-sub-item,
.header0-item-child .item-sub-item,
.header0-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header0-item .item-image,
.header0-item-child .item-image,
.header0-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header0-item .item-title,
.header0-item-child .item-title,
.header0-menu .item-title {
  font-size: 14px;
  color: #fff;
  margin-left: 46px;
}
.header0-item .item-content,
.header0-item-child .item-content,
.header0-menu .item-content {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header0-logo {
    z-index: 101;
  }
  .header0.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header0-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
  }
  .header0-menu li {
    padding: 0 24px;
  }
  .header0-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header0-menu .item-sub-item {
    padding: 8px 0;
  }
  .header0-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header0-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
  }
  .header0-mobile-menu :first-child {
    margin-top: 0;
  }
  .header0 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header0 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header0 .open {
    height: auto;
  }
  .header0 .open .header0-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header0 .open .header0-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header0 .open .header0-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header0 .open > .header0-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header0-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner {
  width: 100%;
  height: 100vh;
  font-size: 1.5em;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("./banner-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner .banner0-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 20%;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  width: 550px;
}
.banner .banner0-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner .banner-title {
  width: 350px;
  min-height: 60px;
  margin: auto 0 60px;
  display: inline-block;
  font-size: 2em;
  font-weight: bold;
  position: relative;
}
.banner .banner-content {
  margin-bottom: 30px;
  word-wrap: break-word;
  min-height: 24px;
}
.banner-button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
  box-shadow: 0 0 0 transparent;
  font-size: 38px;
  height: 2em;
}
.banner-button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px rgba(50, 250, 255, 0.75);
}
.banner-button:focus {
  color: #fff;
  border-color: #fff;
}
.banner-button.queue-anim-leaving {
  width: auto;
}
.banner .banner0-icon {
  bottom: 20px;
  font-size: 24px;
  position: absolute;
  left: 50%;
  margin-left: -12px;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .banner {
    background-attachment: inherit;
  }
  .banner .banner0-text-wrapper {
    width: 90%;
  }
  .banner .banner-title {
    width: 90%;
    left: 0;
  }
}
.content-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content-wrapper .content-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
  font-color: #fff;
}
.content-wrapper .content-block img {
  width: 100%;
}
.content-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content-wrapper .content-block.queue-anim-leaving {
  position: relative !important;
}
.content-wrapper .content-block-icon {
  width: 100px;
  height: 100px;
  margin: auto;
  font-size: 80px;
}
.content-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
.content-wrapper .content-block__interactive:hover * {
  color: #ff7300;
}

.content-block-image {
  width: 350px;
}
.content-wrapper .content-block-image img {
  width: 350px;
}

.content-profile-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .content-wrapper {
    min-height: 880px;
  }
}
.content1-wrapper {
  height: 360px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  padding: 0 32px;
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: 600px;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
